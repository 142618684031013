import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import Text from "../Text/Text";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import {
  getCurrencyObj,
  handleUpdateCheckoutSession,
  satsToBtcAmount,
  showAmount,
  showAmountExceedError,
} from "../constants";
import {
  amountExceedMsg,
  amountValidate,
  presetAmount as presetAmountTxt,
  presetAmountGreaterInvalid,
  presetAmountLesserInvalid,
} from "../../messages";
import CustomNotification from "../Notification/Notification";
import InputMask from "../MaskInput";
import Label from "../Label/Label";
import { PaymentPageContext } from "../../contexts/contexts";
import CommonSkeleton from "../CommonSkeleton";
import { isEmpty } from "lodash";

let timeout;
const PresetAmountPreview = ({
  paymentInfo,
  fontStyle,
  visibleInPaymentPage,
  checkoutSessionId,
  resolution,
  paymentStatus,
  selectedAssetCurrency = "SATS",
  assetAmountLoader,
  paymentDetailsObj,
  selectedPaymentMethod = "lightning",
}) => {
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [apiCalled, setApiCalled] = useState(false);
  const [presetAmount, setPresetAmount] = useState();
  const [assetAmount, setAssetAmount] = useState();
  const [showAssetAmount, setShowAssetAmount] = useState(false);

  // Get context value using consumer
  const paymentPageData = useContext(PaymentPageContext);

  useEffect(() => {
    setShowAssetAmount(false);
  }, [selectedAssetCurrency]);

  const handleAssetAmount = (currentAmount = null) => {
    let finalAmount = 0;

    const exchangeRate = paymentInfo?.exchange_rate || 1;
    const targetCurrency = paymentInfo?.target_currency;
    const amountValue = currentAmount || paymentInfo.amount;
    const lowerAmount = visibleInPaymentPage
      ? showAmount({
          amount: amountValue,
          currency: paymentInfo?.currency?.code,
          divideAmount: true,
        })
      : amountValue;

    if (targetCurrency) {
      finalAmount = exchangeRate * lowerAmount;
    } else if (!isEmpty(paymentDetailsObj)) {
      finalAmount = currentAmount
        ? lowerAmount *
          paymentDetailsObj?.[selectedAssetCurrency]?.exchange_rate
        : paymentDetailsObj?.[selectedAssetCurrency]?.target_amount;
    }

    return selectedAssetCurrency === "SATS"
      ? Math.ceil(finalAmount)
      : finalAmount;
  };

  const handleSetAmounts = (paymentInfo) => {
    const { presetDetails } = paymentInfo;
    setPresetAmount(presetDetails.presetAmount);

    const initialAssetAmount = handleAssetAmount();
    setAssetAmount(initialAssetAmount);
  };

  useEffect(() => {
    if ((paymentInfo && !apiCalled) || (paymentInfo && selectedAssetCurrency)) {
      handleSetAmounts(paymentInfo);
    }
  }, [paymentInfo, apiCalled, selectedAssetCurrency, paymentDetailsObj]);

  const handleSwapCurrency = () => {
    setShowAssetAmount(!showAssetAmount);
  };

  const setValuesAfterError = () => {
    const assetAmountAfterError = handleAssetAmount();
    setAssetAmount(assetAmountAfterError);
    setPresetAmount(paymentInfo?.presetDetails?.presetAmount);
  };

  const updateCheckoutSession = (amount) => {
    if (amount != paymentInfo?.amount) {
      handleUpdateCheckoutSession({
        amount,
        currency: paymentInfo.currency.code,
        paymentPageData,
        checkoutSessionId,
        target_currency: selectedAssetCurrency,
        payment_methods: [selectedPaymentMethod],
      })
        .then(() => {
          const newAssetAmount = handleAssetAmount(amount);
          setAssetAmount(newAssetAmount);
          setPresetAmount(amount);
          setApiCalled(true);
        })
        .catch((errorMessage) => {
          if (errorMessage) {
            setToastMessage(errorMessage);
            setIsToastOpen(true);
          }
          setValuesAfterError();
          setApiCalled(false);
        });
    }
  };

  const makeAPICall = (value) => {
    if (visibleInPaymentPage) {
      // Api call for entered preset amount on payment page
      updateCheckoutSession(value);
    }
  };

  const handleGetFiatAmount = (value) => {
    const targetCurrency = paymentInfo?.target_currency;
    let exchangeRate = targetCurrency
      ? paymentInfo?.exchange_rate
      : paymentDetailsObj?.[selectedAssetCurrency]?.exchange_rate;

    let isPassMultiplier =
      showAssetAmount && paymentInfo?.currency?.code === "USDT";
    const fiatAmount = showAmount({
      amount: value / exchangeRate,
      currency: paymentInfo?.currency?.code,
      ...(isPassMultiplier && { multiplier: 1 }),
    });

    return fiatAmount;
  };

  const handleSetErrorMessage = (errorMsg) => {
    setIsToastOpen(true);
    setToastMessage(errorMsg);
    setValuesAfterError();
  };

  const handleAfterAmountUpdate = (errorMsg, amountForAPI) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (errorMsg !== "") {
        handleSetErrorMessage(errorMsg);
      } else {
        setIsToastOpen(false);
        makeAPICall(amountForAPI);
      }
    }, 1000);
  };

  const checkMinMaxValidation = (value) => {
    const presetData = paymentInfo?.presetDetails;
    const targetCurrency = paymentInfo?.target_currency;

    if (presetData && value) {
      const { limits, minimumAmount, maximumAmount } = presetData;
      let errorMsg = "";

      let exchangeRate = targetCurrency
        ? paymentInfo?.exchange_rate
        : paymentDetailsObj?.[selectedAssetCurrency]?.exchange_rate;

      const assetAmountToFiatAmount = handleGetFiatAmount(value);

      const presetAmountForAPI = showAssetAmount
        ? assetAmountToFiatAmount
        : value;

      if (limits) {
        const compareFiat =
          (paymentInfo?.currency?.code !== selectedAssetCurrency ||
            paymentInfo?.currency?.code !== paymentInfo?.targetCurrency) &&
          !showAssetAmount;

        const minAmount = compareFiat
          ? minimumAmount
          : handleAssetAmount(minimumAmount);

        const maxAmount = compareFiat
          ? maximumAmount
          : handleAssetAmount(maximumAmount);

        if (parseFloat(value) < minAmount || parseFloat(value) > maxAmount) {
          errorMsg =
            parseFloat(value) < minAmount
              ? presetAmountGreaterInvalid(
                  minimumAmount,
                  paymentInfo?.currency?.code
                )
              : presetAmountLesserInvalid(
                  maximumAmount,
                  paymentInfo?.currency?.code
                );
        }
      } else {
        if (showAssetAmount) {
          const btcValue = satsToBtcAmount(value);
          errorMsg =
            parseFloat(btcValue) > 5 ? amountExceedMsg(presetAmount) : "";
        } else {
          const values = {
            currency: paymentInfo?.currency,
            amount: value,
          };
          errorMsg = showAmountExceedError(
            values,
            undefined,
            exchangeRate,
            presetAmountTxt,
            "checkout"
          );
        }
      }

      handleAfterAmountUpdate(errorMsg, presetAmountForAPI);

      let currentAssetAmount = handleAssetAmount(presetAmountForAPI);

      if (showAssetAmount) {
        setAssetAmount(value);
        setPresetAmount(presetAmountForAPI);
      } else {
        setPresetAmount(value);
        setAssetAmount(currentAssetAmount);
      }
    }
  };

  const handleChangeInput = (e) => {
    const value = e.target.value;
    if (timeout) clearTimeout(timeout);

    if (value <= 0) {
      timeout = setTimeout(() => {
        setPresetAmount(value);
        const assetAmountValue = handleAssetAmount(value);
        setAssetAmount(assetAmountValue);
        setIsToastOpen(true);
        setToastMessage(amountValidate);
        setValuesAfterError();
      }, 1000);
    } else {
      checkMinMaxValidation(value);
    }
  };

  const shouldShowAssetAmount =
    selectedAssetCurrency !== paymentInfo?.currency?.code &&
    paymentInfo?.payment?.exchange_rate;

  return (
    <>
      <CustomNotification
        open={isToastOpen}
        onClose={(_event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setIsToastOpen(false);
        }}
        severity="error"
        message={toastMessage}
        className=""
        autoHideDuration={4000}
      />
      {assetAmountLoader ? (
        <CommonSkeleton animation="square" width={250} height={50} />
      ) : (
        <>
          <Box
            className={`preset-amount-preview ${
              resolution !== "web" && "content-center"
            }`}
          >
            <Label
              data-currency={
                showAssetAmount
                  ? selectedAssetCurrency
                  : paymentInfo?.currency?.code
              }
              className={`label-type ${
                paymentStatus === "paid" && "mask-label-disabled"
              }`}
            >
              <InputMask
                extraClass="input-adornment"
                onChange={handleChangeInput}
                name="presetAmount"
                value={showAssetAmount ? assetAmount : presetAmount}
                onKeyDown={(event) => {
                  showAssetAmount &&
                    selectedAssetCurrency === "SATS" &&
                    event.key === "." &&
                    event.preventDefault();
                }}
                disabled={
                  paymentStatus === "paid" ||
                  !paymentInfo?.payment?.exchange_rate
                }
              />
            </Label>
            {/* {shouldShowAssetAmount && (
              <SwapVertIcon
                onClick={handleSwapCurrency}
                sx={{
                  width: "34px",
                  height: "34px",
                  color: fontStyle?.color,
                  marginLeft: "14px",
                }}
              />
            )} */}
          </Box>
          <Text
            className="checkout-acc-currency"
            size={18}
            font="regular"
            variant="subtitle1"
            sx={fontStyle}
          >
            {shouldShowAssetAmount && (
              <>
                (≈{" "}
                {showAssetAmount
                  ? paymentInfo.currency.symbol
                  : getCurrencyObj(selectedAssetCurrency)?.symbol}{" "}
                {showAmount({
                  amount: showAssetAmount ? presetAmount : assetAmount,
                  currency: showAssetAmount
                    ? paymentInfo.currency.code
                    : selectedAssetCurrency,
                  showCommaSeparated: true,
                  multiplier: !visibleInPaymentPage && 1,
                }) || 0}
                )
              </>
            )}
          </Text>
        </>
      )}
    </>
  );
};

export default PresetAmountPreview;
