import axios from "axios";
import * as Sentry from "@sentry/react";
import {
  buildCheckoutResponseData,
  getValidURL,
  setPageTitle,
  showAmount,
} from "@speed/common/src/components/constants";
import { app } from "@speed/common/src/util/firebase";
import { getFirestore, onSnapshot, doc } from "firebase/firestore";
import { isEmpty } from "lodash";

import history from "@speed/common/src/components/history";

const getHeaders = async (method, path, data) => {
  const host = process.env.REACT_APP_API_ENDPOINT;
  const jwtOptions = {
    method,
    url: `${host}${path}`,
  };

  if (data || ["POST", "GET", "DELETE", "PUT"].includes(method)) {
    jwtOptions.data = data;
    jwtOptions.headers = jwtOptions.headers || {};
    jwtOptions.headers["Content-Type"] = "application/json";
    jwtOptions.headers["Accept"] = "application/json";
    jwtOptions.headers["speed-source"] = "c6e190b284633c48e39e55049da3cce8";
  }
  return jwtOptions;
};

export const serverErrorStatusCodes = [500, 502, 503, 504];
export const errorStatusCodes = [400, 401, 402, 403, 404, 408, 415, 428, 429];

const pathsToFetchRawData = ["payment-page"];
// axios API call
export const callAPIInterfaceCheckout = (method, path, data = "") => {
  return new Promise(async (resolve, reject) => {
    let jwtOptions = await getHeaders(method, path, data);
    let apiCall = axios({
      ...jwtOptions,
      transformResponse: (r) =>
        r &&
        (pathsToFetchRawData.includes(path.split("/")[1]) ? r : JSON.parse(r)),
    });

    apiCall
      .then((res) => {
        resolve(res.data);
      })
      .catch(async (err) => {
        if (err.response) {
          if (pathsToFetchRawData.includes(path.split("/")[1])) {
            err.response.data = JSON.parse(err.response.data || "{}");
          }
          const errorStatus = err.response?.status;
          if (serverErrorStatusCodes.includes(errorStatus)) {
            Sentry.captureException(err);
            reject(errorStatus);
          } else if (errorStatusCodes.includes(errorStatus)) {
            reject(err);
          } else reject(err);
        } else if (err.request) {
          Sentry.captureException(err);
          window.location = process.env.REACT_APP_PROMO_WEBSITE;
          reject(503);
        }
      });
  });
};

const regexToFindOptions = /(?:"options":)(\[[^\]]*]|.*?)[,}]/;

export const fetchRawCSData = (data) => {
  const parsedData = JSON.parse(data);
  const matchedString = data?.match(regexToFindOptions)?.[1];
  if (matchedString) {
    const slicedText = matchedString.slice(1, -1);
    const optionsToRepresent = slicedText.split(",");
    return {
      ...parsedData,
      options: optionsToRepresent,
    };
  } else {
    return parsedData;
  }
};

export const loadCheckoutPageData = (checkoutSessionId) => {
  return new Promise(async (resolve, _reject) => {
    await callAPIInterfaceCheckout("GET", `/payment-page/${checkoutSessionId}`)
      .then((response) => {
        const data = fetchRawCSData(response);
        const result = buildCheckoutResponseData(data);
        resolve(result);
        data?.business_name && setPageTitle(data.business_name);
      })
      .catch((err) => {
        _reject(err);
      });
  });
};

export const getCheckoutFireStoreData = (
  setNewCheckoutData,
  checkoutSessionId,
  setPaymentDetails,
  successURL,
  authObj,
  cashbackRedeemLDFlag
) => {
  const db = getFirestore(app);
  if (authObj?.currentUser?.uid) {
    const checkoutSessionRefNew = doc(
      db,
      "account",
      authObj?.currentUser?.uid,
      "checkout_session",
      checkoutSessionId
    );

    onSnapshot(
      checkoutSessionRefNew,
      async (querySnapshot) => {
        const data = querySnapshot.data();
        if (data) {
          if (data.status === "deactivated") {
            history.push("/pay/error/deactivated-link");
            return;
          } else if (data.status === "paid" && successURL) {
            if (!data?.cashback?.cashback_id || !cashbackRedeemLDFlag) {
              window.location.href = getValidURL(successURL);
            } else {
              setPaymentDetails(data);
            }
          } else {
            if (data.status === "paid") {
              sessionStorage.clear();
            }

            const paymentId = sessionStorage.getItem("cl_payment_id");
            if (
              data.payment_id &&
              paymentId !== data.payment_id &&
              data.status !== "paid"
            ) {
              const callAPI = paymentId ? true : false;
              await setNewCheckoutData(true, callAPI);
            }

            if (data.status !== "paid") {
              sessionStorage.setItem("cl_payment_id", data.payment_id);
            }
            setPaymentDetails(data);
          }
        } else {
          setPaymentDetails(null);
        }
      },
      async (_e) => {
        await authObj?.currentUser?.getIdToken();
      }
    );
  } else history.push("/pay/error/under-maintenance");
};

export const handleGetPayment = async (props) => {
  const {
    cs_id,
    paymentInfo,
    selectedAssetCurrency,
    selectedPaymentMethod,
    paymentDetailsObj,
    setPaymentDetailsObj,
    setAssetAmountLoader,
    setAssetQrLoader,
    setIsToastOpen,
    setToastMessage,
  } = props;

  const getPaymentData = {
    target_currency: selectedAssetCurrency,
    payment_methods: [selectedPaymentMethod],
  };
  try {
    !paymentDetailsObj?.[selectedAssetCurrency]?.target_amount &&
      setAssetAmountLoader(true);
    setAssetQrLoader(true);
    const paymentResponse = await callAPIInterfaceCheckout(
      "POST",
      `/payment-page/${cs_id}/payment`,
      getPaymentData
    );
    const data = fetchRawCSData(paymentResponse);

    let updatedPaymentDetailsObj = {};

    Object.keys(data?.payment_method_options).forEach((key) => {
      let checkKey = key === "on_chain" ? "onchain" : key;

      if (
        !isEmpty(paymentDetailsObj) &&
        checkKey in paymentDetailsObj?.[selectedAssetCurrency]
      ) {
        const lowerAmount = showAmount({
          amount: paymentInfo?.amount,
          currency: paymentInfo?.currency?.code,
          divideAmount: true,
        });
        const targetAmount = lowerAmount * data?.exchange_rate;

        updatedPaymentDetailsObj = {
          ...paymentDetailsObj,
          [selectedAssetCurrency]: {
            ...paymentDetailsObj?.[selectedAssetCurrency],
            [checkKey]: data?.payment_method_options[key]?.payment_request,
            exchange_rate: data?.exchange_rate,
            target_amount:
              selectedAssetCurrency === "SATS"
                ? Math.ceil(targetAmount)
                : targetAmount,
          },
        };
      }
    });

    setPaymentDetailsObj(updatedPaymentDetailsObj);
  } catch (err) {
    const errorMsg = err?.response?.data?.errors?.[0]?.message;
    if (errorMsg) {
      setIsToastOpen(true);
      setToastMessage(errorMsg);
    }
  } finally {
    setAssetAmountLoader(false);
    setAssetQrLoader(false);
  }
};

export const getPaymentAddressOfSelectedCurrency = ({ ...props }) => {
  const { selectedPaymentMethod, paymentDetailsObj, selectedAssetCurrency } =
    props;

  const check =
    paymentDetailsObj?.[selectedAssetCurrency]?.[selectedPaymentMethod];
  // call payment API only if the the address is not available in payment details obj
  if (!check) {
    handleGetPayment(props);
  }
};
