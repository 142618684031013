import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ComputerRoundedIcon from "@mui/icons-material/ComputerRounded";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import InfoIcon from "@mui/icons-material/Info";
import PhoneAndroidRoundedIcon from "@mui/icons-material/PhoneAndroidRounded";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Box } from "@mui/system";
import * as Sentry from "@sentry/react";
import SentryRRWeb from "@sentry/rrweb";
import {
  callAPIInterfaceCheckout,
  fetchRawCSData,
} from "@speed/checkout/src/components/constants";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import InputSelectionBox from "@speed/common/src/components/InputSelectionBox/InputSelectionBox";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import Text from "@speed/common/src/components/Text/Text";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import axios from "axios";
import classNames from "classnames";
import Color from "color";
import { enc } from "crypto-js";
import OSREC from "currencyformatter.js";
import { compact, find, isEmpty, isEqual, sortBy } from "lodash";
import moment from "moment-timezone";
import { validate } from "postal-codes-js";
import { hotjar } from "react-hotjar";
import { isValidPhoneNumber } from "react-phone-number-input/input";
import { sessionService } from "redux-react-session";
import * as rrweb from "rrweb";
import parser from "ua-parser-js";
import * as yup from "yup";
import {
  amountExceedMsg,
  amountValidate,
  anyAmount,
  otpHasExpiredMsg,
  otpWillExpireMsg,
  reportLabel,
  resendMsg,
  twoFASubMsgEmail,
  twoFASubMsgPhone,
  validAmount,
  validEndAmountGreaterStartAmountMsg,
  validEndAmountMsg,
  validStartAmountMsg,
  viewCalculationLabel,
} from "../messages";
import CommonInBothPreview from "./CommonInBothPreview";
import { CustomLink } from "./Link/Link";
import { Countries } from "./country";
import { currency } from "./currency";
import {
  accountIcons,
  dummyImage,
  satsLightning,
  satsOnchain,
  usdtEthereum,
  usdtLightning,
} from "./images";

export const speedVersion = "2022-10-15";

const getHeaders = async (method, path, data) => {
  const host = process.env.REACT_APP_API_ENDPOINT;
  const jwtOptions = {
    method,
    url: `${host}${path}`,
  };

  if (data || ["POST", "GET", "DELETE", "PUT"].includes(method)) {
    jwtOptions.data = data;
    jwtOptions.headers = jwtOptions.headers || {};
    jwtOptions.headers["Content-Type"] = "application/json";
    jwtOptions.headers["Accept"] = "application/json";
    jwtOptions.headers["speed-source"] = "c6e190b284633c48e39e55049da3cce8";

    await sessionService
      .loadSession()
      .then(async (session) => {
        if (session.access_token) {
          jwtOptions.headers["Access"] = session.access_token;
          jwtOptions.headers["speed-version"] = speedVersion;
        }
        if (session.id_token) {
          jwtOptions.headers["Authorization"] = session.id_token;
        }
        if (session.liveMode !== undefined) {
          jwtOptions.headers["speed-livemode"] = session.liveMode;
        }
        if (session.speed_acc_id) {
          jwtOptions.headers["speed-account"] = session.speed_acc_id;
        }
      })
      .catch((_err) => {});
  }
  return jwtOptions;
};

export const serverErrorStatusCodes = [500, 502, 503, 504];
export const errorStatusCodes = [400, 401, 402, 403, 404, 408, 415, 428, 429];

// axios API call
export const callAPIInterfaceCommon = (method, path, data = "") => {
  return new Promise(async (resolve, reject) => {
    let jwtOptions = await getHeaders(method, path, data);
    let apiCall = axios(jwtOptions);
    apiCall
      .then((res) => {
        resolve(res.data);
      })
      .catch(async (err) => {
        if (err.response) {
          const errorStatus = err.response.status;
          if (serverErrorStatusCodes.includes(errorStatus)) {
            Sentry.captureException(err);
            reject(errorStatus);
          } else if (errorStatusCodes.includes(errorStatus)) {
            reject(err);
          } else reject(err);
        } else if (err.request) {
          Sentry.captureException(err);
          reject(503);
        }
      });
  });
};

export const redirectWhenEntityNotFound = (err) => {
  const errors = err?.response?.data?.errors;
  const errorType = errors && errors[0]?.type && errors[0]?.type.toLowerCase();
  return errorType === "invalid_request_error" ? true : false;
};

export const getCountryObj = (key, value, countryList = Countries) => {
  return find(countryList, { [key]: value });
};

export const validateColor = (hexColor) => {
  return /^#([0-9a-f]{3}){1,2}$/i.test(hexColor);
};

export const getAccountName = (currentAccount) => {
  return currentAccount?.account?.account_public_info
    ? currentAccount.account.account_public_info?.business_name
    : currentAccount?.account?.name;
};

export const getCurrentTimeInSecond = (expiresTime) => {
  const seconds = Math.floor(
    (new Date(expiresTime).getTime() - new Date().getTime()) / 1000
  );
  return seconds > 0 ? seconds : 0;
};

export const convertExponentialToDecimal = (exponentialNumber) => {
  const str = exponentialNumber?.toString();
  if (str?.indexOf("e") !== -1) {
    let extraExponentDigits =
      str.indexOf("e") - (str.includes(".") ? str.indexOf(".") : 0) - 1; //this will used for the value like 3.5e-7 where it contains decimal (.) notation in number
    const exponent = parseInt(str.split("-")[1], 10);
    return exponentialNumber.toFixed(exponent + extraExponentDigits);
  } else {
    return exponentialNumber;
  }
};

export const satsToBtcAmount = (satAmount) => {
  return convertExponentialToDecimal(+(satAmount / 100000000).toFixed(8));
};

export const btcToSatsAmount = (btcAmount) => {
  return convertExponentialToDecimal(+(btcAmount * 100000000).toFixed(8));
};

export const satsToFiatAmount = (satsValue, exchangeRate, decimal = 16) => {
  return parseFloat((satsValue / exchangeRate).toFixed(decimal));
};

export const regexToTruncateZeros = /(\.\d*[1-9])0+$|\.0*$/;

export const getNumberFormatAccordingToCurrency = (amount, currency) => {
  const currenciesSupportedByOSREC = Object.keys(OSREC?.defaultLocales);
  const params = {
    currency: currenciesSupportedByOSREC?.includes(currency) ? currency : "USD",
    symbol: "",
    valueOnError: amount,
  };

  return OSREC?.format(amount, params) || amount;
};

export const removeTrailingZeros = (amount, currency) => {
  if (amount !== null && amount !== undefined) {
    amount = Number(amount); // Convert amount to Number type
    amount = isNaN(amount) ? 0 : amount.toFixed(2); // Round off the amount
    amount = getNumberFormatAccordingToCurrency(amount, currency); // format amount
    return amount.replace(regexToTruncateZeros, "$1"); // remove trailling zeros
  } else {
    return "0";
  }
};

export const getSatsAmount = (data) => {
  if (data?.payment && data?.amount >= 0) {
    let satAmount;
    if (data?.currency?.code === "SATS") {
      satAmount = Math.ceil(data.amount);
    } else {
      const exchange_rate =
        data?.currency?.code === "BTC"
          ? 100000000
          : data.payment?.exchange_rate;
      if (!exchange_rate) return " ";

      satAmount = Math.ceil(exchange_rate * data.amount);
    }
    return `${getNumberFormatAccordingToCurrency(
      convertExponentialToDecimal(+satAmount?.toFixed(8)),
      data?.currency?.code
    ).replace(regexToTruncateZeros, "$1")} SATS`;
  } else {
    return "0.0 SATS";
  }
};

export const getPreviewTotalAmount = ({
  data,
  paymentDetailsObj,
  visibleInPaymentPage = false,
  withoutBTC = false,
  selectedAssetCurrency = "SATS",
}) => {
  if (data && data.payment && data.payment.exchange_rate) {
    let satAmount;
    let exchange_rate = 1;

    if (data?.currency?.code === "BTC") {
      exchange_rate = 100000000;
    } else if (!isEmpty(paymentDetailsObj)) {
      exchange_rate = paymentDetailsObj?.[selectedAssetCurrency]?.exchange_rate;
    } else {
      exchange_rate = data.payment.exchange_rate;
    }

    if (data?.currency?.code === "SATS") {
      satAmount = Math.ceil(data.amount);
    } else {
      let amountVisibleInPaymentPage = 0;
      if (data?.assets?.length) {
        // when target currency is null from the payment page response
        if (!isEmpty(paymentDetailsObj)) {
          amountVisibleInPaymentPage =
            data?.amount * paymentDetailsObj?.["SATS"]?.exchange_rate;
        } else {
          amountVisibleInPaymentPage = data?.amount * exchange_rate;
        }
      } else {
        // when target currency is not null
        amountVisibleInPaymentPage = data.target_amount_pending;
      }
      satAmount = visibleInPaymentPage
        ? amountVisibleInPaymentPage
        : Math.ceil(exchange_rate * data.amount);
    }
    return withoutBTC
      ? satsToBtcAmount(satAmount)
      : `${satsToBtcAmount(satAmount)} BTC`;
  } else {
    return "0.0 BTC";
  }
};

export const getAmountByCurrency = (amount, currency) => {
  let amountValue;
  if (currency === "BTC") {
    amountValue = satsToBtcAmount(100000000 * amount);
  } else {
    amountValue = convertExponentialToDecimal(+amount?.toFixed(16));
  }
  return amountValue;
};

export const getCurrencyFormat = (data) => {
  const code = data?.currency?.code;
  const symbol = data?.currency?.symbol;
  const amount = data?.amount;
  const uuid = data?.uuid;
  const multiplier = data?.multiplier;

  if ((excludedCode?.includes(code) && uuid === "SATS") || code === uuid) {
    return "";
  } else {
    return amount ? (
      <>
        (≈ {symbol}{" "}
        {showAmount({
          amount,
          currency: uuid,
          showCommaSeparated: true,
          multiplier,
        })}
        )
      </>
    ) : (
      data && <>(≈ {data?.currency?.symbol}0)</>
    );
  }
};

export const getBrandingPreviewColor = (brandingPageUI) => {
  if (brandingPageUI) {
    const { primarySavedColor, primary_color } = brandingPageUI;
    return primarySavedColor || primary_color || "#2250A1";
  } else {
    return "#2250A1";
  }
};

export const getLightColor = (hex, lum) => {
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;
  // convert to decimal and change luminosity
  let rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }
  return rgb;
};

export const isFontAvailable = (fontsFamily) => {
  const FontFaceObserver = require("fontfaceobserver");
  return new Promise(async (resolve, reject) => {
    let font = await new FontFaceObserver(fontsFamily);
    font
      .load()
      .then(() => {
        resolve(true);
      })
      .catch(() => reject(false));
  });
};

export const staticInvoiceData = {
  currency: { name: "US Dollar", code: "USD", symbol: "$" },
  amount: 141.6,
  exchange_rate: 4972.014667403492,
  payment_request: "",
  invoice_line_items: [
    {
      id: 1,
      product_images: [],
      name: "Design",
      quantity: 10,
      currency: "USD",
      unit_amount: 10.0,
      row_total_amount: 100.0,
    },
    {
      id: 2,
      product_images: [],
      name: "Branding",
      quantity: 1,
      currency: "USD",
      unit_amount: 41,
      row_total_amount: 41.0,
    },
    {
      id: 3,
      product_images: [],
      name: "Logo Design",
      quantity: 10,
      currency: "USD",
      unit_amount: 20.0,
      row_total_amount: 70.0,
    },
    {
      id: 4,
      product_images: [],
      name: "Webflow design",
      quantity: 10,
      currency: "USD",
      unit_amount: 20.0,
      row_total_amount: 160.0,
    },
  ],
  amount_pending: 2350.0,
  amount_paid: 0,
  // currency: "USD",
  invoice_number: "INV-DRAFT",
  invoice_due_date: new Date().getTime(),
  customer: {
    name: "Speed App",
  },
  memo: "For questions or concerns, please contact at support@tryspeed.com",
};

export const staticPaymentCheckoutData = {
  currency: { name: "US Dollar", code: "USD", symbol: "$" },
  amount: 141.6,
  payment: { exchange_rate: 4972.014667403492, payment_request: "" },
  details: {
    pageTitle: "Raising donation for Dogs",
    pageDescription:
      "Supporting dogs in need! Help us raise funds for their care, medical treatment, and shelter. Your donations make a difference, providing love and a brighter future for our furry friends. Join us in making a positive impact today!",
    image: dummyImage,
  },
};

export const getCurrencyObj = (code) => {
  if (typeof code !== "string") return { name: code, symbol: code, code };
  let currencyObj = currency?.find((cur) => cur.code === code?.toUpperCase());
  if (!currencyObj) {
    currencyObj = { name: code, symbol: code, code };
  }
  return currencyObj;
};

export const getServerTime = () => {
  let xmlHttp = new XMLHttpRequest();
  xmlHttp.open("HEAD", window.location.href.toString(), false);
  xmlHttp.setRequestHeader("Content-Type", "text/html");
  xmlHttp.send("");
  return xmlHttp.getResponseHeader("Date");
};

export const setPageTitle = (titleText) => {
  document.title = titleText;
};

export const sentryInitialization = () => {
  Sentry.init({
    dsn: process.env.REACT_APP_MONITORING_PATH,
    integrations: [
      new SentryRRWeb({
        packFn: rrweb.pack,
      }),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
    ],
    attachStacktrace: true,
    tracesSampleRate: 1.0, //Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
};

export const isLightColor = (color) => {
  return Color(color).luminosity() > 0.5;
};

export const pathArray = [
  "/dashboard",
  "/payments",
  "/prices",
  "/checkout-links",
  "/payment-links",
  "/payment-addresses",
  "/invoices",
  "/withdraws",
  "/withdrawal-links",
  "/payouts",
  "/cashbacks",
  "/balances",
  "/swaps",
  "/transactions",
  "/customers",
  "/products",
  "/manage-apps",
  "/merchant-accounts",
  "/wallet-accounts",
  "/merchant-transactions",
  "/wallet-transactions",
  "/reports",
  "/one-qr",
  "/users",
  "/search",
  "/transfers",
  "/connect",
  "guide",
  "/api-catalog",
  "/manage-accounts",
  "/promo-code",
  "/referral",
  "/speed-point-of-sale",
  "/my-assets",
  "/assets",
  "/buy-bitcoin",
  "/download",
  "/selector",
  "/directory",
  "/node",
  "/transfer",
  "/unprocessed-withdraws",
  "/maintenance",
  "/feedbacks",
  "/instant-sends",
];

export const checkMainMenu = (pathname) => {
  return pathArray.some((path) => {
    return path === `/${pathname.split("/")[1]}`;
  });
};

export const getMainMenuRoute = (pathname) => {
  let mainMenuRoute;
  if (pathArray.includes(pathname)) {
    mainMenuRoute = pathname;
  } else {
    pathArray.some((path) => {
      if (pathname.includes(path)) {
        mainMenuRoute = path;
      }
    });
  }
  return mainMenuRoute;
};

export const defaultDateFormat = "YYYY/MM/DD";

export const commonDateFormatLogic = (timestamp, dateFormat, timezone) => {
  const momentObj = (time) => {
    if (time) {
      return timezone ? moment(time).tz(timezone) : moment(time);
    } else {
      return moment();
    }
  };

  const currentDate = momentObj().format(defaultDateFormat);
  const yesterday = momentObj().subtract(1, "days").format(defaultDateFormat);

  if (timestamp) {
    if (momentObj(timestamp).format(defaultDateFormat) === currentDate) {
      return `Today, ${momentObj(timestamp).format("hh:mm A")}`;
    } else if (momentObj(timestamp).format(defaultDateFormat) === yesterday) {
      return `Yesterday, ${momentObj(timestamp).format("hh:mm A")}`;
    } else if (dateFormat) {
      return momentObj(timestamp).format(dateFormat.toUpperCase() + ", h:mm A");
    } else {
      return momentObj(timestamp).format(defaultDateFormat + ", h:mm A");
    }
  }
};

export const dateTimeFormat = (
  timestamp,
  dateFormat = null,
  timezone = null
) => {
  return commonDateFormatLogic(timestamp, dateFormat, timezone);
};

export const utcTimestampForDashboard = ({
  timezone,
  isStartDay,
  operation,
  typeOfSubstraction = "days",
  days,
}) => {
  const localDate = isStartDay
    ? moment.tz(timezone).startOf("day")
    : moment.tz(timezone).endOf("day"); // Get start or end of the day in local timezone
  const utcDate = moment.utc(localDate); // Get end of the day in utc for local timezone
  let timestamp = utcDate.valueOf();

  if (days) {
    timestamp = utcDate[operation](days, typeOfSubstraction).valueOf(); // timestamp after add or subtract days
  }

  return timestamp;
};

export const linkStatus = {
  active: {
    label: "Active",
    variant: "primary",
    value: "active",
  },
  verified: {
    label: "Verified",
    variant: "success",
    value: "verified",
  },
  deactivated: {
    label: "Deactivated",
    variant: "default",
    value: "deactivated",
  },
  paid: {
    label: "Paid",
    variant: "success",
    value: "paid",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  expired: {
    label: "Expired",
    variant: "default",
    value: "expired",
    tooltip: "The payment expired because the exchange rate changed.",
    icon: <AccessTimeFilledOutlinedIcon className="status-icon" />,
  },
  unpaid: {
    label: "Unpaid",
    variant: "warning",
    value: "unpaid",
    tooltip: "The payment is still pending.",
    icon: <ErrorOutlinedIcon className="status-icon" />,
  },
  pending: {
    label: "Invited",
    variant: "warning",
    value: "pending",
    icon: <ErrorOutlinedIcon className="status-icon" />,
  },
  cancelled: {
    label: "Cancelled",
    variant: "error-primary",
    value: "cancelled",
    tooltip: "The payment source has been deactivated.",
    icon: <CancelIcon className="status-icon" />,
  },
  unverified: {
    label: "Unverified",
    variant: "warning",
    value: "unverified",
  },
  available: {
    label: "Available",
    variant: "primary",
    value: "Available",
  },
  archive: {
    label: "Archived",
    variant: "default",
    value: "archived",
  },
  failed: {
    label: "Failed",
    variant: "error-primary",
    value: "failed",
  },
};

export const invoiceStatus = {
  draft: {
    label: "Draft",
    variant: "default",
    value: "draft",
  },
  outstanding: {
    label: "Outstanding",
    variant: "default",
    value: "outstanding",
  },
  pastDue: {
    label: "Past Due",
    variant: "success",
    value: "pastDue",
  },
  paid: {
    label: "Paid",
    variant: "success",
    value: "paid",
  },
  open: {
    label: "Open",
    variant: "primary",
    value: "open",
  },
  void: {
    label: "Void",
    variant: "error-primary",
    value: "void",
  },
  uncollectable: {
    label: "Uncollectable",
    variant: "error-primary",
    value: "uncollectable",
  },
  overdue: {
    label: "Overdue",
    variant: "warning",
    value: "overdue",
  },
};

export const payoutStatus = {
  paid: {
    label: "Paid",
    variant: "success",
    icon: <CheckCircleIcon />,
    value: "paid",
    tooltip:
      "Your payout request successfully processed, please check your payout wallet balance.",
  },
  unpaid: {
    label: "Unpaid",
    variant: "warning",
    icon: <AccessTimeFilledIcon />,
    value: "unpaid",
    tooltip:
      "Your payout request successfully processed, please check your payout wallet balance.",
  },
  in_progress: {
    label: "In-Progress",
    variant: "warning",
    icon: <AccessTimeFilledIcon />,
    value: "in_progress",
    tooltip: "We are processing your payout request.",
  },
  pending: {
    label: "Pending",
    variant: "default",
    icon: <InfoIcon />,
    value: "pending",
    tooltip: "Your payout request is currently under evaluation.",
  },
  hold: {
    label: "Hold",
    variant: "error-primary",
    icon: <CancelIcon />,
    value: "hold",
    tooltip: "Please check your registered email address for details.",
  },
  failed: {
    label: "Failed",
    variant: "error-primary",
    icon: <CancelIcon className="status-icon" />,
    value: "failed",
  },
};

export const importWLStatus = {
  processing: {
    label: "Processing",
    variant: "default",
    value: "processing",
    icon: <AccessTimeFilledOutlinedIcon className="status-icon" />,
  },
  sent: {
    label: "Sent",
    variant: "success",
    value: "sent",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  failed: {
    label: "Failed",
    variant: "error-primary",
    value: "failed",
    icon: <CancelIcon className="status-icon" />,
  },
};

export const autoPayoutStatus = {
  active: {
    label: "Active",
    variant: "success",
    value: "active",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  deactivated: {
    label: "Deactivated",
    variant: "default",
    value: "deactivated",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  on_hold: {
    label: "On Hold",
    variant: "error-primary",
    value: "on_hold",
    icon: <CancelIcon className="status-icon" />,
  },
};

export const accountStatus = {
  active: {
    label: "Active",
    variant: "primary",
    value: "active",
  },
  restricted: {
    label: "Restricted",
    variant: "warning",
    value: "restricted",
  },
  suspended: {
    label: "Suspended",
    variant: "warning",
    value: "suspended",
  },
  closure_in_progress: {
    label: "Closure Request",
    variant: "error-primary",
    value: "closure_in_progress",
  },
  closed: {
    label: "Closed",
    variant: "default",
    value: "closed",
  },
};

export const affiliatePartnerStatus = {
  ACTIVE: {
    label: "Active",
    variant: "primary",
    value: "active",
  },
  EXPIRED: {
    label: "Expired",
    variant: "default",
    value: "expired",
  },
};

export const nodeStatus = {
  active: {
    label: "Active",
    variant: "success",
    value: "active",
  },
  suspended: {
    label: "Suspended",
    variant: "error-primary",
    value: "suspended",
  },
};

export const withdrawStatus = {
  paid: {
    label: "Paid",
    variant: "success",
    value: "paid",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  unpaid: {
    label: "Unpaid",
    variant: "warning",
    value: "unpaid",
    icon: <ErrorOutlinedIcon className="status-icon" />,
  },
  failed: {
    label: "Failed",
    variant: "error-primary",
    value: "failed",
    icon: <CancelIcon className="status-icon" />,
  },
};

export const paymentStatus = {
  PAID: {
    label: "Paid",
    variant: "success",
    value: "paid",
    // icon: <CheckCircleIcon className="status-icon" />,
  },
  UNPAID: {
    label: "Unpaid",
    variant: "warning",
    value: "unpaid",
    // icon: <ErrorOutlinedIcon className="status-icon" />,
  },
  CANCELLED: {
    label: "Cancelled",
    variant: "error-primary",
    value: "cancelled",
    // icon: <CancelIcon className="status-icon" />,
  },
  EXPIRED: {
    label: "Expired",
    variant: "default",
    value: "expired",
  },
};

const receivePaymentObj = {
  module: "CREATE_PAYMENT",
  name: "Receive payments",
  is_restricted: false,
  reason_id: null,
};

const sendPaymentsObj = {
  module: "CREATE_WITHDRAW",
  name: "Send payments",
  is_restricted: false,
  reason_id: null,
};

const swapObj = {
  module: "SWAP",
  name: "Swap",
  is_restricted: false,
  reason_id: null,
};

const transferObj = {
  module: "TRANSFER",
  name: "Transfer",
  is_restricted: false,
  reason_id: null,
};

const depositObj = {
  module: "DEPOSIT",
  name: "Deposit",
  is_restricted: false,
  reason_id: null,
};

const payoutObj = {
  module: "PAYOUT",
  name: "Payout",
  is_restricted: false,
  reason_id: null,
};

export const getManageRestrictionModulesLists = (
  forMerchanantAccount = false
) => {
  const moduleListObj = {
    merchantList: [
      receivePaymentObj,
      sendPaymentsObj,
      swapObj,
      transferObj,
      payoutObj,
    ],
    walletList: [
      receivePaymentObj,
      sendPaymentsObj,
      swapObj,
      depositObj,
      payoutObj,
    ],
  };
  return forMerchanantAccount
    ? moduleListObj.merchantList
    : moduleListObj.walletList;
};

export const webHookStatus = {
  succeeded: {
    label: "Succeeded",
    variant: "success",
    value: "succeeded",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  failed: {
    label: "Failed",
    variant: "error-primary",
    value: "failed",
    icon: <CancelIcon className="status-icon" />,
  },
};

export const transactionStatus = {
  pending: {
    label: "Pending",
    variant: "warning",
    icon: <ErrorOutlinedIcon className="status-icon" />,
    value: "pending",
  },
  complete: {
    label: "Completed",
    variant: "success",
    value: "complete",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  completed: {
    label: "Completed",
    variant: "success",
    value: "complete",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  failed: {
    label: "Failed",
    variant: "error-primary",
    value: "failed",
  },
};

export const reportStatus = {
  SUCCEEDED: {
    label: "Completed",
    variant: "success",
    value: "complete",
  },
  FAILED: {
    label: "Failed",
    variant: "error-primary",
    value: "failed",
  },
  PENDING: {
    label: "In progress",
    variant: "warning",
    value: "in_progress",
  },
};

export const clipboardElement = (
  paymentLinkText,
  isActivePaymentLink,
  customClass,
  type,
  copyLink = false,
  clipBoardClass = "",
  shouldCopyIconVisible = true
) => {
  return (
    <Box style={{ display: "flex", alignItems: "center" }}>
      <Box onClick={(event) => event.stopPropagation()}>
        {type === "text" ? (
          <Text
            className={classNames("default-text", customClass)}
            font="regular"
            size={16}
            variant="subtitle1"
          >
            {paymentLinkText}
          </Text>
        ) : (
          <InputSelectionBox
            customWidth={354}
            text={paymentLinkText}
            copyText={copyLink}
            disabled={!isActivePaymentLink}
            className={customClass}
          />
        )}
      </Box>
      {isActivePaymentLink && shouldCopyIconVisible && (
        <Box
          style={{ marginLeft: "15px", paddingTop: "4px" }}
          onClick={(event) => event.stopPropagation()}
          className={clipBoardClass}
        >
          <Clipboard text={paymentLinkText} />
        </Box>
      )}
    </Box>
  );
};

export const renderPayoutStatus = (status) => {
  const tagProps = {
    text: payoutStatus[status]?.label,
    variant: payoutStatus[status]?.variant,
    icon: payoutStatus[status]?.icon,
  };

  if (payoutStatus[status]?.label) {
    if (!payoutStatus[status]?.tooltip) {
      return <Tag {...tagProps} className="custom-payouts-status-label" />;
    } else {
      return (
        <CustomTooltip
          arrow={true}
          placement={"top"}
          text={<>{payoutStatus[status].tooltip}</>}
          className="custom-tooltip payouts-status-tooltip"
        >
          <Box sx={{ width: "fit-content", boxShadow: "none" }}>
            <Tag
              className="pointer-cursor custom-payout-tag-icon custom-payouts-status-label"
              {...tagProps}
            />
          </Box>
        </CustomTooltip>
      );
    }
  } else {
    return null;
  }
};

export const getValidURL = (url) => {
  return !url.match(/^http?:\/\//i) && !url.match(/^https?:\/\//i)
    ? "http://" + url
    : url;
};

export const htmlDecode = (input) => {
  let e = document.createElement("textarea");
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
};

export const setQueryParamsForTab = (tabData, newValue) => {
  let currentRoute = find(tabData, { value: `${newValue}` });
  return newValue === "1" ? null : currentRoute?.tabName;
};
export const searchableModules = {
  checkout_link: "Checkout Links",
  checkout_session: "Checkout Sessions",
  payment_link: "Payment Links",
  payment: "Payments",
  balance_transaction: "Transactions",
  withdraw: "Withdraws",
  product: "Products",
  customer: "Customers",
};

export const actionBgColor = {
  paid: {
    color: "#2ecc71",
  },
  deactivated: {
    color: "#ff7365",
  },
  error: {
    color: "#e2e4e9",
  },
  amountExceed: {
    color: "#ff7365",
  },
};

export const hotJarInitialization = () => {
  hotjar.initialize(process.env.REACT_APP_HOTJAR_SITE_ID, 6);
};

export const showMenuByLdFlag = (ldFlags, menuLdFlagName) => {
  return !(ldFlags && menuLdFlagName && !ldFlags[menuLdFlagName]);
};

export const selectTabOnSearch = (value, tabData, setSelectedTab) => {
  if (value) {
    let currentRoute = find(tabData, { tabName: value });
    setSelectedTab(currentRoute?.value || "1");
  }
};

export const dummyLightningAddress =
  "lntb80n1p32rte6pp5wkyjvhy94v276upew5te9csxjw5raw83wjderehffdj99898dfsdfdfdsfewrp28y72nwpjk2epqf35k66t5v4jzqcm0d4cxzmnecqzpgxqzjcsp5my747celzphtw7udwlmfzt64ny0fpmpvy76jpqw2qh7kpku07t4s9qyyssq8vgfzewtdsjau0tqgqfvfauju7n9hynchw0felv77d8z9h7nk69shlc0n2yh3yd3xkj44xx8h3t9u6femfe45dvrr6md6k60zsj2gfsq36y6ty";
export const dummyOnchainAddress = "tb1q52edvjud6ekg3hx43e7qkl9dgcgcstgue8m6x8";
export const dummyLnurl =
  "lnurl1ep68gurn8ghj7ctswpshq6fww3e8jumsv4jkgtnyv4m7mrww4exc0m384nrwvpcxvcrvef6x6enjephvsckgvrzv78rxc3jvcmrvdeevvurxvtzv6nrsdmxxucnxc3evopnzdpkxasnwcmxvgerjdfjxenxzceepte48s";
export const dummyEthereumAddress = "0xb264e2fb94ffe6d6295496f42e522bf95d13235";
export const dummyLightningUSDTAddress =
  "lntb17u1pnvds56pp556cq9pcwstp5jamzcc0spmtp5zxg54tfx0ns0xvsrjqf04qrql8qdqqcqzzsxqzjcrzjqdttgc33sg27mgjv040funk729a8ylycnxkx4jljww3edfpprgmeyv3wvcsecna835qqqqqqqqqqqqqqqqsp5x0m02k7ke455rqqmx5jylmcqx5jr8dcqa2sm2jtwf6k94t2ezkws9qxpqysgqnhf8fqacdzpn4kk99xt86y0ma85ujmelrugtwnrk2zp3h8vfdn25z7qr5kntmp3zaawggn2hhz09ku27qtzkkql9vn2ae2qy5thkf4gpgtgn7";

export const getBrandingTheme = (
  brandingPageUI,
  setLoader,
  setFontFamily,
  setTextColor
) => {
  if (brandingPageUI) {
    const { checkout_font_family, primary_color, primarySavedColor } =
      brandingPageUI;
    if (checkout_font_family !== "system-ui") {
      setLoader(true);
      isFontAvailable(checkout_font_family)
        .then((res) => {
          res && setLoader(false);
        })
        .catch(() => setLoader(false));
    }
    setFontFamily(checkout_font_family || "system-ui");
    const selectedColor = validateColor(primary_color)
      ? primary_color
      : primarySavedColor;
    isLightColor(selectedColor)
      ? setTextColor("#0a193e")
      : setTextColor("#fff");
  }
};

export const getCommonAllProps = (props) => {
  const {
    fontFamily,
    textColor,
    toggleFlag,
    loader,
    setToggleFlag,
    from,
    paymentInfo,
    brandingPageUI,
    currentAccount,
    isMultiPaymentMethods,
    visibleInPaymentPage,
    selectedAssetCurrency,
    paymentDetailsObj,
  } = props;

  const fontStyle = {
    fontFamily: `${fontFamily} !important`,
    color: textColor,
  };

  const textStyle = {
    fontFamily: fontStyle.fontFamily,
    color: `${fontStyle.color} !important`,
  };
  const paymentData = paymentInfo;

  const previewColor = getBrandingPreviewColor(brandingPageUI);

  const accountName = currentAccount && getAccountName(currentAccount);

  let convertTotalAmount;

  if (toggleFlag) {
    if (selectedAssetCurrency && selectedAssetCurrency !== "SATS") {
      convertTotalAmount = getSelectedCurrencyAmount(
        paymentDetailsObj?.[selectedAssetCurrency]?.target_amount,
        selectedAssetCurrency,
        1
      );
    } else {
      convertTotalAmount = getSatsAmount(paymentInfo);
    }
  } else {
    convertTotalAmount = getPreviewTotalAmount({
      data: paymentInfo,
      paymentDetailsObj,
      visibleInPaymentPage,
    });
  }

  const isZeroAmountLink =
    paymentInfo?.payment?.target_amount === 0 ||
    paymentInfo?.payment?.target_amount_pending === 0 ||
    paymentInfo?.amount === 0 ||
    (paymentInfo?.amount === 0 &&
      paymentInfo?.customer &&
      paymentInfo?.amountType === "anyAmount");

  const totalAmount = isZeroAmountLink ? anyAmount : convertTotalAmount;

  const showPartialPaymentPreviewModules = ["checkout", "invoice"];

  const isPartialPaymentAvailable =
    isMultiPaymentMethods &&
    paymentData?.payments?.length > 0 &&
    showPartialPaymentPreviewModules.includes(from);

  const totalProduct = paymentInfo?.invoice_line_items;

  const additionalProps = {
    fontStyle,
    textStyle,
    paymentData,
    previewColor,
    accountName,
    isZeroAmountLink,
    totalAmount,
    loader,
    toggleFlag,
    setToggleFlag,
    totalProduct,
  };

  return {
    additionalProps,
    isPartialPaymentAvailable,
  };
};

export const renderFrequentComponent = (component, props) => {
  return <CommonInBothPreview component={component} {...props} />;
};

export const dummyPaymentID = "pi_XXXXXXXXXXXXXXXX";
export const withdrawalLinkDummyId = "wr_xxxxxxxxxxxxxxxx";
const regexToFindAmount = /(?:"amount":)(.*?)(?=,)/g;

export const fetchRawBalanceData = (data) => {
  const parsedData = JSON.parse(data)?.available?.map((x, i) => {
    const amountToRepresent = data
      ?.match(regexToFindAmount)
      [i]?.split('"amount":')?.[1];
    return {
      ...x,
      amount: amountToRepresent || x?.amount,
    };
  });
  return parsedData;
};

export const fetchPrecisedInteger = (num, precision) => {
  if (!num || !Number(num)) return 0;

  const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,${precision}})?`);
  let res = num?.toString()?.match(regex);
  return res?.[0] ? res[0].replace(regexToTruncateZeros, "$1") : num;
};

export const validationCheck = (
  fieldName,
  satsState,
  amountExceedState,
  errors
) => {
  return (
    (errors[fieldName] && Boolean(errors[fieldName])) ||
    (satsState && Boolean(satsState)) ||
    (amountExceedState && Boolean(amountExceedState))
  );
};

export const amountValidation = yup
  .number()
  .typeError(validAmount)
  .moreThan(0, amountValidate);
export const excludedCode = ["BTC", "SATS"];
export const getTimezoneMomentObj = (time, timezone) => {
  return timezone ? moment(time).tz(timezone) : moment(time);
};

export const dateFromTimestamp = (timestamp, dateFormat, timezone) => {
  return getTimezoneMomentObj(timestamp, timezone).format(
    dateFormat ? dateFormat.toUpperCase() : defaultDateFormat
  );
};

export const getFormTotalAmount = (values, currentExchangeRate) => {
  return getPreviewTotalAmount({
    data: {
      currency: values.currency,
      amount: parseFloat(values.amount) || 0.0,
      payment: {
        exchange_rate: excludedCode.includes(values.currency.code)
          ? parseFloat(values.amount) || 0.0
          : currentExchangeRate,
      },
    },
  });
};

export const showAmountExceedError = (
  values,
  setAmountExceed,
  currentExchangeRate,
  linkType,
  from = ""
) => {
  const totalAmount = getFormTotalAmount(values, currentExchangeRate);
  const check =
    parseFloat(totalAmount.split(" ")[0]) > 5 ? amountExceedMsg(linkType) : "";

  // check if amount is exceed to 5 BTC then show error message
  if (from === "checkout") return check;
  else setAmountExceed(check);
};

export const formattedCustomField = (customFields) => {
  return (
    customFields?.length > 0 &&
    customFields.map((item) => {
      let payload = { ...item };
      if (item?.options) {
        const formattedOptions = item.options.map((item) => {
          return { option: item };
        });
        payload = {
          ...payload,
          options: formattedOptions,
        };
      }
      return payload;
    })
  );
};

export const buildCheckoutResponseData = (response) => {
  if (response) {
    const otherInfo = response.title ? true : false;
    const amount = response.api_version
      ? response.amount_pending || response?.amount
      : response.amount;
    const currency = response.currency;

    const customFields = formattedCustomField(response.custom_fields);
    const globalStates = {
      id: response.id,
      targetCurrency: response.target_currency,
      currentAccount: {
        account: {
          name: response.business_name,
          account_type: "Merchant",
          account_public_info: {
            business_name: response.business_name,
            branding: response.branding,
          },
        },
        success_url: response.success_url,
        cancel_url: response.cancel_url,
      },
      brandingPageUI: response.branding,
      paymentInfo: {
        ...response,
        currency: getCurrencyObj(response.currency),
        amount: +showAmount({ amount, currency }),
        status: response.status,
        otherInfo: otherInfo,
        details: {
          pageTitle: response.title,
          pageDescription: response.title_description,
          image: response.title_image,
        },
        amountType:
          response.type === "options"
            ? "amountOptions"
            : response.type || (response.amount === 0 ? "anyAmount" : ""),
        customer_collections_status: response.customer_collections_status,
        customer_collections: response.customer_collections,
        custom_fields: customFields || {},
        ...(!response?.api_version && {
          expires_at: response?.payment?.expires_at,
          payment: {
            ...response.payment,
            payment_method: "lightning",
            target_currency: "SATS",
            payment_method_options: {
              lightning: {
                payment_request: response?.payment?.payment_request,
              },
            },
          },
        }),
      },
      customFieldUpdatedFrom: "details-side",
      redirectToWebsitePreview: false,
      liveMode: response.livemode,
      isMultiPaymentMethods: response.payment?.payment_method_options
        ? true
        : false,
      firebaseToken: response.firebase_token,
      paymentPageSettingsObj: {
        ...response.payment_page_settings,
        show_payment_page_speed_branding:
          !response.payment_page_settings?.show_payment_page_speed_branding,
      },
    };
    if (response.type === "preset") {
      const presetAmount = showAmount({ amount: response.amount, currency });
      const minPresetAmount = showAmount({
        amount: response.preset?.min_amount,
        currency,
      });
      const maxPresetAmount = showAmount({
        amount: response.preset?.max_amount,
        currency,
      });

      globalStates.paymentInfo["presetDetails"] = {
        presetAmount: +presetAmount,
        limits: Boolean(+minPresetAmount && +maxPresetAmount),
        minimumAmount: +minPresetAmount || 0,
        maximumAmount: +maxPresetAmount || 0,
      };
    }

    if (response.type === "options") {
      globalStates.paymentInfo["amountOptions"] = response.options.map(
        (option) => {
          const optionAmount = showAmount({ amount: option, currency });
          return +optionAmount;
        }
      );
    }

    return globalStates;
  }
};

export const withdrawalLinkTab = "withdrawalLinkTab";
export const linkTypes = ["withdrawal link", withdrawalLinkTab];
export const maxSat = 500000000;
export const maxUsdt = 25000;
export const minSat = 1;

export const handleUpdateCheckoutSession = ({
  amount,
  currency,
  paymentPageData,
  checkoutSessionId,
  target_currency = "SATS",
  payment_methods = ["lightning"],
}) => {
  return new Promise((resolve, reject) => {
    const { setQrCodeLoader, setCheckoutData, checkoutData } = paymentPageData;
    setQrCodeLoader(true);

    // set amount based on target currency;
    amount = +showAmount({
      amount,
      currency,
      divideAmount: currency === "USDT",
    });

    callAPIInterfaceCheckout(
      "POST",
      `/payment-page/${checkoutSessionId}/amount`,
      { amount, target_currency, payment_methods }
    )
      .then((res) => {
        const data = fetchRawCSData(res);
        const amount = data.api_version ? data.amount_pending : data.amount;
        const currency = data.currency;
        const presetAmount = showAmount({ amount: data.amount, currency });

        const reformResult = {
          ...checkoutData,
          paymentInfo: {
            ...checkoutData.paymentInfo,
            amount: +showAmount({ amount, currency }),
            amount_pending: data.amount_pending,
            target_amount_pending: data.target_amount_pending,
            expires_at: data?.expires_at,
            payment: data?.payment,
            presetDetails: {
              ...checkoutData.paymentInfo.presetDetails,
              presetAmount: +presetAmount,
            },
          },
        };
        setCheckoutData(reformResult);
        setQrCodeLoader(false);
        resolve(reformResult);
      })
      .catch((e) => {
        const errorMessage = e.response?.data?.errors?.[0]?.message;
        setQrCodeLoader(false);
        reject(errorMessage);
      });
  });
};
export const walletAllowedSettingsRoute = [
  "Profile Info",
  "Change Password",
  "2FA",
  "Login Sessions",
  "Associated Accounts",
  "Account Details",
  "Account Status",
];

export const viewCalculation = (
  fontStyle,
  setState,
  textSx = {},
  iconSx = {}
) => {
  return (
    <Text
      className={classNames("grey-text", "pointer-cursor")}
      size={12}
      sx={{
        fontFamily: fontStyle.fontFamily,
        color: `${fontStyle.color} !important`,
        ...textSx,
      }}
      font="regular"
      variant="subtitle1"
      onClick={() => setState(true)}
      withIcon="end"
    >
      {viewCalculationLabel}
      <ArrowForwardIosIcon
        sx={{
          height: "12px",
          width: "12px",
          ml: "4px !important",
          color: `${fontStyle.color} !important`,
          ...iconSx,
        }}
      />
    </Text>
  );
};

export const removeAllEmptyFieldsFromNestedObj = (object) => {
  const objToArryKeyValuePair = Object.entries(object); // convert object to array
  const arrayWithRemovedEmptyValues = objToArryKeyValuePair.filter(
    ([_Key, value]) => value != null && value != "" && value != undefined
  );
  const resultArrayInKeyValue =
    arrayWithRemovedEmptyValues?.length > 0 &&
    arrayWithRemovedEmptyValues
      .map(([key, v]) => {
        const value =
          typeof v == "object" && !Array.isArray(v)
            ? removeAllEmptyFieldsFromNestedObj(v)
            : v;
        if (value) {
          return [key, value];
        }
      })
      .filter(Boolean); // remove undefined values ex: a=  [1, undefined] > [1] from mapped array

  if (resultArrayInKeyValue?.length > 0)
    return Object.fromEntries(resultArrayInKeyValue); // convert array to object again
};

export const integerWithCurrency = (
  currency,
  amount,
  showCommaSeparated = false
) => (
  <>
    {getCurrencyObj(currency)?.symbol}
    {showAmount({ amount, currency, showCommaSeparated })}
  </>
);

export const getTotalAmount = (products, form) => {
  let sum = 0;
  products?.length &&
    products?.forEach((item) => {
      sum =
        sum +
        item?.quantity *
          (form === "api-data" ? item?.prices?.unit_amount : item?.unit_amount);
    });

  return sum;
};
export const totalAmountInvoiceUI = (products, currency) => (
  <>
    {getCurrencyObj(currency?.code)?.symbol}
    {getAmountByCurrency(getTotalAmount(products), currency?.code)}
  </>
);
export const addDaysToCurrentDate = (days) => {
  let now = new Date();
  now.setDate(now.getDate() + days);
  return now;
};

export const validatePostalCode = (postalCode, countryISO) => {
  if (postalCode && countryISO) {
    try {
      return validate(countryISO, postalCode) === true ? true : false;
    } catch (e) {
      return false;
    }
  } else {
    return true;
  }
};

export const validatePhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    try {
      return isValidPhoneNumber(phoneNumber);
    } catch (e) {
      return false;
    }
  } else {
    return true;
  }
};

export const getFormattedWalletsData = (walletNames) => {
  return new Promise((resolve) => {
    const walletNamesArr =
      walletNames?.length > 0 &&
      walletNames.map((item, index) => {
        return `filters[name][$in][${index}]=${item}&`;
      });

    const filterPath = walletNamesArr && walletNamesArr.join("");

    const host = process.env.REACT_APP_STRAPI_API_ENDPOINT;
    const token = process.env.REACT_APP_STRAPI_API_TOKEN;

    const jwtOptions = {
      method: "GET",
      url: `${host}/api/pay-via-wallets?populate=*&${filterPath}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if (filterPath) {
      axios(jwtOptions)
        .then((res) => {
          const response = res.data?.data;
          if (response) {
            const result = response.map((item) => {
              const { name, logo } = item.attributes;
              return {
                id: item.id,
                name,
                logoUrl: logo.data?.attributes?.url,
              };
            });
            resolve(reOrderWalletArray(walletNames, result));
          }
        })
        .catch(() => {});
    }
  });
};

export const reOrderWalletArray = (selectedWalletArr, defaultWalletArr) => {
  const result =
    selectedWalletArr?.length > 0 &&
    selectedWalletArr.map((item) =>
      defaultWalletArr?.find((wallet) => wallet.name === item)
    );
  return compact(result);
};

export const isAlphaNumericCharAllowed = /^[a-z\d\-.,\s]+$/i;

export const DiagonalDiv = ({
  children,
  mainDivSx = {},
  previewColor = "",
}) => {
  return (
    <>
      <Box
        className="vector-diagonal-div"
        sx={{
          backgroundColor: previewColor && `${previewColor} !important`,
          ...mainDivSx,
        }}
      />
      <Box
        className="vector-diagonal-sub-div"
        sx={{
          backgroundColor: previewColor && `${previewColor} !important`,
        }}
      />
      <Box className="overlay-container">{children}</Box>
    </>
  );
};

export const getDefaultImage = (color, currentAccount) =>
  accountIcons({ fill: color })[
    currentAccount?.account?.account_type || "Merchant"
  ];

export const checkLinkOptionsAvailable = ({
  paymentInfo,
  showEditedContactInfo,
  paymentPageData,
  checkedBillingAddress,
  checkedEmailAddress,
  checkedPhoneAddress,
  checkedShippingAddress,
  submitClicked,
}) => {
  const checkedLengthKeys = [
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
  ];
  const checkedLength = checkedLengthKeys.filter(Boolean).length;

  const showCustomerCollections =
    paymentPageData?.checkoutData?.paymentInfo?.customer_collections ||
    paymentInfo?.customer_collections ||
    {};

  // custom field form visibility
  const isOptionalArr = [];
  const CFResult =
    paymentInfo?.custom_fields?.length > 0 &&
    paymentInfo.custom_fields.map((item) => {
      isOptionalArr.push(item.is_optional);
      return item.is_optional
        ? !item?.value || item.value === ""
        : !(item.hasOwnProperty("value") && item?.value !== "");
    });

  const checkForSubmitClicked =
    Boolean(submitClicked) &&
    CFResult?.length > 0 &&
    CFResult?.every((item) => item)
      ? false
      : CFResult?.length > 0 && CFResult.every((item) => item);

  const checkForOptional = isOptionalArr.includes(true)
    ? checkForSubmitClicked
    : CFResult?.length > 0 && CFResult?.includes(true);

  const customFieldFormCheck = CFResult?.length > 0 ? checkForOptional : false;

  // custom field detail visibility
  const customFieldDetailsCheck =
    !showEditedContactInfo &&
    paymentInfo?.custom_fields?.length > 0 &&
    paymentInfo.custom_fields
      .map((item) => {
        return (
          (item.hasOwnProperty("value") && item?.value !== "") ||
          item.is_optional
        );
      })
      ?.includes(true);

  // contact info form visibility
  const isContactInfoFormVisible =
    showEditedContactInfo ||
    (showCustomerCollections &&
      Object.keys(showCustomerCollections)?.length !== checkedLength);

  // contact info details visibility
  const isContactDetailsVisible =
    !showEditedContactInfo &&
    checkedLength > 0 &&
    showCustomerCollections &&
    Object.keys(showCustomerCollections)?.length === checkedLength;

  const isCustomFieldVisible = showEditedContactInfo || customFieldFormCheck;
  const contactFormExist = !isEmpty(showCustomerCollections) && !submitClicked;
  const customFieldExist = paymentInfo?.custom_fields?.length > 0;

  const visibleDetailForm = Boolean(
    isContactInfoFormVisible || isCustomFieldVisible
  );

  const isDetailsSectionVisible =
    customFieldDetailsCheck || isContactDetailsVisible;

  return {
    contactFormExist,
    customFieldExist,
    isDetailsSectionVisible,
    visibleDetailForm,
    showCustomerCollections,
    isContactInfoFormVisible,
    isCustomFieldVisible,
    checkedLengthKeys,
  };
};

// Restrict special characters in input
export const checkSpecialCharacter = (e) => {
  if (!/[0-9a-zA-Z]/.test(e.key) && e.key !== ".") {
    e.preventDefault();
  }
};

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

//function for generating breadcrumbs based on routes
export const generateBreadcrumbs = (breadCrumbData, props) => {
  return breadCrumbData
    .filter(({ route }) => props.match.path.includes(route))
    .map(({ route, ...rest }) => ({
      route: Object.keys(props.match.params).length
        ? Object.keys(props.match.params).reduce(
            (newRoute, param) =>
              newRoute.replace(`:${param}`, props.match.params[param]),
            route
          )
        : route,
      ...rest,
    }));
};

export const operatorList = {
  string: [
    {
      id: 1,
      label: "=",
      value: "eq",
    },
    {
      id: 2,
      label: "!=",
      value: "neq",
    },
    {
      id: 3,
      label: ">",
      value: "gt",
    },
    {
      id: 4,
      label: ">=",
      value: "gte",
    },
    {
      id: 5,
      label: "<",
      value: "lt",
    },
    {
      id: 6,
      label: "<=",
      value: "lte",
    },
    {
      id: 7,
      label: "Is between",
      value: "between",
    },
  ],
};

export const getReduxFilterValues = (
  appliedFilterObj,
  filterKey,
  isForAmount = false
) => {
  const filteredValueArray = appliedFilterObj?.filter;
  if (filteredValueArray?.length) {
    const filteredObj = find(filteredValueArray, {
      field: filterKey,
    });
    return isForAmount ? filteredObj : filteredObj?.value;
  } else return "";
};

export const getMasked = (phone) => {
  return phone.replace(/\s/g, "").replace(/\d(?=\d{4})/g, "*");
};

export const TwoFASubText = ({
  isFromAuth,
  usingPhone,
  phoneNumber,
  loginEmail,
  alignCenter = false,
  textFontFamily = "",
  ...props
}) => {
  const boldText = (text) => (
    <Text
      component="span"
      size={!isFromAuth ? 14 : 18}
      font="semibold"
      sx={{ fontFamily: `${textFontFamily} !important`, fontWeight: 600 }}
    >
      {text}
    </Text>
  );
  return (
    <Text
      align={!isFromAuth ? "inherit" : alignCenter ? "center" : ""}
      font="regular"
      className={props.from !== "profile" ? "margin-top10" : ""}
      size={!isFromAuth ? 14 : 18}
      variant="subtitle1"
      sx={{ fontFamily: textFontFamily }}
    >
      {usingPhone
        ? twoFASubMsgPhone(
            boldText(phoneNumber.code),
            boldText(getMasked(phoneNumber.number)),
            isFromAuth && loginEmail && boldText(loginEmail)
          )
        : twoFASubMsgEmail}
    </Text>
  );
};

export const MFAOTPTimer = ({
  timerCount,
  fromConnect = false,
  textFontFamily = {},
}) => (
  <Box
    sx={{
      display: "flex",
      marginTop: fromConnect ? "55px" : "25px",
      justifyContent: "center",
    }}
  >
    <Text
      variant="h4"
      size={16}
      font="regular"
      align="inherit"
      className="grey-text"
      sx={{
        lineHeight: "150% !important",
        fontFamily: textFontFamily && `${textFontFamily} !important`,
      }}
    >
      {otpWillExpireMsg}
    </Text>
    <Text
      variant="h4"
      className="default-text"
      font="bold"
      sx={{
        marginLeft: "5px",
        fontFamily: textFontFamily && `${textFontFamily} !important`,
      }}
      size={16}
    >
      {`${Math.floor(timerCount / 60)}`.padStart(2, 0)}:
      {`${timerCount % 60}`.padStart(2, 0)}
    </Text>
  </Box>
);

export const DidNotGetCodeLink = ({
  isFromAuth,
  textProps,
  linkProps,
  ...props
}) => (
  <Text
    size={!isFromAuth ? 14 : 16}
    font="semibold"
    align={!isFromAuth ? "inherit" : "center"}
    className={classNames("default-text margin-top25", textProps.className)}
    sx={{ ...textProps.textFontFamily }}
  >
    {otpHasExpiredMsg}
    <CustomLink
      size={!isFromAuth ? "small" : "large"}
      bold
      className="margin-left10"
      onClick={props.handleResendCode}
      {...linkProps}
      sx={{ ...textProps.textFontFamily }}
    >
      {resendMsg}
    </CustomLink>
  </Text>
);
export const isAlphaNumeric = /^[a-z\d\s]+$/i;

export const truncateString = (str, maxLength) => {
  if (str?.length > maxLength) {
    return str.substring(0, maxLength) + "...";
  }
  return str;
};

export const truncateStringWithTooltip = ({ str, textProps, maxLength }) => {
  return str.length > maxLength ? (
    <CustomTooltip
      arrow={true}
      placement="top"
      text={
        <Text
          size={14}
          className="default-text"
          font="regular"
          sx={{ wordBreak: "break-all" }}
        >
          {str}
        </Text>
      }
      className="custom-tooltip"
    >
      <Box>
        <Text {...textProps}>{truncateString(str, maxLength)}</Text>
      </Box>
    </CustomTooltip>
  ) : (
    <Text {...textProps}>{truncateString(str, maxLength)}</Text>
  );
};

export const openIntercomChatModal = () => {
  if (window?.Intercom) {
    window.Intercom("show");
  }
};

export const capitalizeFirstChar = (string) => {
  if (string)
    return string?.charAt(0)?.toUpperCase() + string?.toLowerCase()?.slice(1);
  else return "";
};

export const roundOffAmount = (amount, precision) =>
  convertExponentialToDecimal(+amount?.toFixed(precision));

export const isSameArray = (array1, array2) =>
  isEqual(
    sortBy(array1, (o) => o?.id),
    sortBy(array2, (o) => o?.id)
  );

export const displaySidebarItem = (
  item,
  flags,
  currentAccount,
  user = null
) => {
  const showItemByLdFlag = showMenuByLdFlag(flags, item.ldFlagName);
  const showItemByRole = !item?.restrictedRoles?.includes(
    currentAccount?.role?.id
  );
  const showForSocialLogin = !(
    item?.restrictionForSocialMediaLoginUser && user?.signup_method !== "email"
  );
  return showItemByLdFlag && showItemByRole && showForSocialLogin;
};

export const getDisplayAmount = (
  showFiat,
  amount,
  code,
  amountInBtc = false
) => {
  let formattedAmt = 0;
  let amt = Number(amount);
  if (!isNaN(amt)) {
    if (showFiat) {
      if (amt == 0) {
        // Show 0 if the fiat amount is 0
      } else if (fetchPrecisedInteger(amt, 2) === "0") {
        // Show upto 8 fraction if the fiat amount is less than 0.00
        formattedAmt = amt.toFixed(8);
      } else {
        formattedAmt = getNumberFormatAccordingToCurrency(amt, code);
      }
    } else {
      if (code === "SATS") {
        if (amountInBtc) {
          const btcAmount = amt
            ?.toString()
            ?.replace(regexToTruncateZeros, "$1");
          return convertExponentialToDecimal(+btcAmount);
        } else {
          amt = amt.toFixed(0);
        }
      }
      formattedAmt = getNumberFormatAccordingToCurrency(amt, code);
    }
  }

  formattedAmt = formattedAmt.toString();
  return formattedAmt?.replace(regexToTruncateZeros, "$1");
};

export const extractParamsRegex = /([?&])([^=]+)=([^&#;\s]+)/g;
export const extractAddressRegex = /[?#&]/g;
export const validAddress = /^[\w?:&=#.\-@]+$/;
export const isValidLnUrlFormatRegex =
  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const getStepsBeforeIndex = (steps, index, childIndex = null) => {
  const result = { 0: [0] };
  for (let i = 0; i <= index; i++) {
    if (steps[i]) {
      result[i] = steps[i].children.map((_, childIndex) => childIndex);
    } else {
      result[i] = [];
    }
  }
  const lastObjectValue = Object.entries(result)?.pop()[1];
  lastObjectValue?.splice(childIndex ? childIndex + 1 : 1);
  return result;
};
export const createFilterObjSchema = (fieldType, fieldOperator, fieldValue) => {
  return { field: fieldType, operator: fieldOperator, value: fieldValue };
};

export const commaSeperatedCurrencyAmount = (amount, currency) =>
  getNumberFormatAccordingToCurrency(
    fetchPrecisedInteger(amount, 2),
    currency
  )?.replace(regexToTruncateZeros, "$1");

export const uniqueMethod = () =>
  yup.addMethod(yup.object, "uniqueProperty", function (propertyName, message) {
    return this.test("unique", message, function (value) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const filtered = this.parent
        .filter((v) => v !== value)
        .some(
          (v) => v[propertyName]?.toString() === value[propertyName]?.toString()
        );

      if (filtered) {
        throw this.createError({
          path: `${this.path}.${propertyName}`,
        });
      }

      return true;
    });
  });

export const getUniqueId = () => new Date().getTime().toString(36);

export const sortDataForGivenSequence = (list, keyName, sequence) => {
  const priority = {};
  sequence.forEach((item, index) => (priority[item] = index));
  return list.sort(
    (itemA, itemB) => priority[itemA[keyName]] - priority[itemB[keyName]]
  );
};

export const decodeBase64EncodedString = (encodedString) => {
  if (encodedString) {
    const decodedString = enc.Utf8.stringify(enc.Base64.parse(encodedString));
    return JSON.parse(decodedString);
  }
  return encodedString;
};

export const ssoData = (history, search) => {
  const getURLData = (keyName) => new URLSearchParams(search).get(keyName);

  const signupMethod =
    history?.location?.pathname === "/google-auth" ? "google" : "apple";
  const authCode = getURLData("code");
  const decodedAuthState = decodeBase64EncodedString(getURLData("state"));
  const userData = JSON.parse(decodeURIComponent(getURLData("user")));
  return { signupMethod, authCode, decodedAuthState, userData };
};

export const apiCallSSOCommon = ({
  decodedAuthState,
  redirectFn,
  authCode,
  signupMethod,
  userData,
}) => {
  const { redirectToLocalhostUrl } = decodedAuthState || {};

  const localhostUrl = "http://localhost:3000";
  const isRedirectToLocalhost = window.location.origin !== localhostUrl;

  //We are applying isRedirectToLocalhost in condition to prevent looping once redirect to localhost from app urls
  if (isRedirectToLocalhost && redirectToLocalhostUrl) {
    const localhostRedirectUrl = window.location.href?.replace(
      process.env.REACT_APP_URL,
      localhostUrl
    );
    window.location.assign(localhostRedirectUrl);
    return;
  }

  if (!authCode) {
    redirectFn();
    return;
  }
  const apiPayload = {
    auth_code: authCode,
    signup_method: signupMethod,
  };

  if (signupMethod === "apple" && userData) {
    apiPayload.first_name = userData.name?.firstName;
    apiPayload.last_name = userData.name?.lastName;
  }
  return apiPayload;
};

export const createRedirectionAuthURL = (authType, state = {}) => {
  const redirectURL = new URL(
    authType === "google"
      ? process.env.REACT_APP_GOOGLE_SSO_URL
      : process.env.REACT_APP_APPLE_SSO_URL
  );
  if (Object.keys(state).length) {
    const rawStr = JSON.stringify(state);
    const encodedState = enc.Base64.stringify(enc.Utf8.parse(rawStr));
    redirectURL.searchParams.append("state", encodedState);
  }
  return redirectURL;
};

export const handleSSOLoginRedirection = (
  authMethod,
  state,
  localhostSsoFe = false
) => {
  if (localhostSsoFe) {
    state = {
      ...state,
      redirectToLocalhostUrl: true,
    };
  }

  const redirectionURL = createRedirectionAuthURL(authMethod, state);
  window.open(redirectionURL, "_self");
};

export const connectSSOSource = "Connect";

export const paymentMethodsArr = [
  {
    value: "lightning",
    label: "Lightning",
  },
  {
    value: "onchain",
    label: "On-chain",
  },
];

export const currencyMethodsArr = {
  SATS: [
    {
      value: "lightning",
      label: "Lightning",
    },
    {
      value: "onchain",
      label: "On-chain",
    },
  ],
  USDT: [
    {
      value: "lightning",
      label: "Lightning",
    },
    {
      value: "ethereum",
      label: "Ethereum",
    },
  ],
};

export const allowedLNurl = ["payRequest", "withdrawRequest"];

export const compareArraysOfObjects = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return true;
  }
  const seenKeys = new Set();
  for (const obj1 of arr1) {
    const obj2 = arr2.find((obj) => obj.id === obj1.id);
    if (!obj2) {
      return true;
    }
    for (const key in obj1) {
      seenKeys.add(key);
      if (obj1[key] !== obj2[key]) {
        return true;
      }
    }
    for (const key in obj2) {
      if (!seenKeys.has(key)) {
        return true;
      }
    }
  }
  return false;
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  // Remove dragged item
  const [removed] = result.splice(startIndex, 1);
  // Add dropped item
  result.splice(endIndex, 0, removed);
  return result;
};

export const renderUIBasedOnDevice = (setState) => {
  const mobileElement = document.querySelector(".checkout-mobile-preview");
  const webElement = document.querySelector(".checkout-web-preview");
  const computedStyle = mobileElement && window.getComputedStyle(mobileElement);
  const computedStyleWeb = webElement && window.getComputedStyle(webElement);
  const isWebViewRendered = computedStyleWeb?.display === "none";
  setState(isWebViewRendered ? true : computedStyle?.display === "flex");
};

export const reportIssueTextComponent = ({
  setReportIssueModal,
  shouldModalOpen,
}) => {
  return (
    <Text
      size={12}
      font="semibold"
      variant="inline"
      withIcon="start"
      className={`grey-text ${
        shouldModalOpen && "pointer-cursor"
      } report-issue-text`}
      onClick={() => shouldModalOpen && setReportIssueModal(true)}
    >
      <WarningAmberRoundedIcon
        sx={{
          width: "20px",
          height: "19px",
        }}
      />
      {reportLabel}
    </Text>
  );
};

export const getCountryData = (country) => {
  const findCountry = Countries.find(
    (currCountry) => currCountry.short_name === country
  );
  return findCountry;
};

export const getCurrencyData = (currencyCode) => {
  const findCurrency = currency.find(
    (currCurrency) => currCurrency?.code === currencyCode
  );
  return findCurrency;
};

export const getDefaultWalletCurrencyObj = (country) => {
  const countryData = getCountryData(country);
  const currencyData = getCurrencyData(countryData?.currency_code);
  const defaultCurrencyObj = {
    name: currencyData?.name,
    code: currencyData?.code,
    symbol: currencyData?.symbol,
  };
  return defaultCurrencyObj;
};

export const infoTitleElement = (title, fontSize = 16) => (
  <Text className="default-text" size={fontSize}>
    {title}
  </Text>
);
export const getWalletsData = (setWalletData) => {
  return new Promise(() => {
    const host = process.env.REACT_APP_STRAPI_API_ENDPOINT;
    const token = process.env.REACT_APP_STRAPI_API_TOKEN;

    const jwtOptions = {
      method: "GET",
      url: `${host}/api/pay-via-wallets?populate=*&pagination[limit]=150`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios(jwtOptions)
      .then((res) => {
        if (res.data?.data) {
          const featuredList = [],
            unFeaturedList = [];
          res.data?.data.forEach((item) => {
            const { name, logo, featured } = item.attributes;
            const walletObj = {
              id: item.id,
              name,
              logoUrl: logo.data.attributes.url,
              featured,
            };

            if (featured) featuredList.push(walletObj);
            else unFeaturedList.push(walletObj);
          });
          const finalArray = [...featuredList, ...unFeaturedList];
          setWalletData(finalArray);
        }
      })
      .catch(() => {});
  });
};

export const userAgentBox = (userAgent, showYouLabel = false) => {
  let ua, json_ua;
  try {
    // json user agent for pos,wallet address (mobile app)
    // precaution : don't change it without mobile team permission
    json_ua = JSON.parse(userAgent);
  } catch (_e) {
    //user agent for desktop
    ua = parser(userAgent);
  }

  const renderRoundedIcon = (ua) => {
    if (ua?.browser.name !== undefined && ua?.os.name !== undefined) {
      return ua?.device.type == "mobile" ? (
        <PhoneAndroidRoundedIcon />
      ) : (
        <ComputerRoundedIcon />
      );
    }
  };

  return (
    <Box className="flex-inline">
      <Box className="icon-wrapper">
        {" "}
        {ua ? renderRoundedIcon(ua) : json_ua && <PhoneAndroidRoundedIcon />}
      </Box>
      <Box sx={{ paddingLeft: "15px" }}>
        {ua?.browser.name}{" "}
        {ua?.os.name && "- " + ua?.os.name + " " + ua?.os.version + " "}
        {json_ua &&
          json_ua?.device_model +
            " - " +
            json_ua?.platform +
            " " +
            json_ua?.device_version +
            " - " +
            json_ua?.app_type +
            " "}
        {showYouLabel && "(You)"}
      </Box>
    </Box>
  );
};

export const checkCurrentAndExpireTime = (expires_at) =>
  expires_at < Math.floor(new Date().getTime() / 1000);

export const allowedImageTypes = ["jpeg", "jpg", "png", "jfif"];

export const validateEmail = (email) => {
  const validEmailregex =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return validEmailregex.test(email.toLowerCase());
};

export const cryptoCurrency = {
  BTC: { name: "Bitcoin", code: "BTC", symbol: "฿" },
  SATS: {
    name: "Satoshi",
    code: "SATS",
    symbol: (
      <span
        style={{
          fontFamily: "Satoshi-Symbol",
          height: "24px",
          paddingTop: "1.5px",
        }}
      >
        !
      </span>
    ),
  },
};

const scaleFactor = {
  SATS: {
    SATS: 1,
    BTC: 0.00000001,
  },
  USDT: {
    USDT: 0.01,
  },
  BTC: {
    SATS: 100_000_000,
    BTC: 1,
  },
};

const scaleFactorTargetAmount = {
  SATS: {
    SATS: 1,
    BTC: 0.00000001,
  },
  USDT: {
    USDT: 0.01,
  },
  USD: {
    USD: 0.01,
  },
};

/**
 * Calculates the amount based on the given currency and targeted currency.
 *
 * @param {object} options - The options for calculating the amount.
 * @param {number} options.amount - The amount to be calculated.
 * @param {string} options.currency - The currency of the amount.
 * @param {number | null} options.multiplier - The multiplier param used to handle scale property.
 * @param {boolean} [options.showCommaSeparated=false] - Whether to show the amount with comma separators.
 * @param {string} [options.targetedCurrency=currency] - The target currency to convert the amount to.
 * @param {boolean} [options.appendCurrency=false] - Whether to append the target currency to the formatted amount.
 * @param {boolean} [options.divideAmount=false] - Whether to divide/multiply the amount by scale or not.
 * @return {string} The formatted amount based on the target currency.
 */
export const showAmount = ({
  amount,
  currency,
  multiplier = null,
  showCommaSeparated = false,
  targetedCurrency = currency,
  appendCurrency = false,
  divideAmount = false,
  isTargetAmount = false,
}) => {
  let scale;
  if (multiplier) {
    scale = multiplier;
  } else if (isTargetAmount) {
    scale = scaleFactorTargetAmount[currency]?.[targetedCurrency] || 1;
  } else {
    scale = scaleFactor[currency]?.[targetedCurrency] || 1;
  }
  let finalAmount = divideAmount ? amount / scale : amount * scale;
  finalAmount = finalAmount.toFixed(8);

  let formattedAmount =
    showCommaSeparated && targetedCurrency !== "BTC"
      ? getNumberFormatAccordingToCurrency(finalAmount, targetedCurrency)
      : finalAmount;
  formattedAmount = formattedAmount.replace(regexToTruncateZeros, "$1");

  return appendCurrency
    ? `${formattedAmount} ${targetedCurrency}`
    : formattedAmount;
};

export const instantSendTypes = {
  lightning: "PER_TXN_LIGHTNING_LIMIT",
  onchain: "PER_TXN_ONCHAIN_LIMIT",
  usdt: "PER_TXN_LIMIT",
  daily: "DAILY_TXN_LIMIT",
};

export const promoCodeStatus = {
  active: {
    label: "Active",
    variant: "primary",
    value: "active",
  },
  pending: {
    label: "Pending",
    variant: "warning",
    value: "pending",
  },
  deactivated: {
    label: "Deactivated",
    variant: "default",
    value: "deactivated",
  },
  expired: {
    label: "Expired",
    variant: "default",
    value: "expired",
  },
  paid: {
    label: "Paid",
    variant: "success",
    value: "paid",
    icon: <CheckCircleIcon className="status-icon" />,
  },
  canceled: {
    label: "Cancelled",
    variant: "error-primary",
    value: "cancelled",
    icon: <CancelIcon className="status-icon" />,
  },
  used: {
    label: "Used",
    variant: "default",
    value: "used",
  },
};

export const referralStatus = {
  active: {
    label: "Active",
    variant: "primary",
    value: "active",
  },
  paid: {
    label: "Paid",
    variant: "success",
    value: "paid",
  },
  cancelled: {
    label: "Rejected",
    variant: "error-primary",
    value: "rejected",
  },
};

export const allowedTwoAfterDecimalRegex = /^\d+(\.\d{0,2})?$/;
export const spaceNotAllowed = /\s/;

export const paymentMethodData = {
  pm_1: {
    icon: satsLightning,
    value: "lightning",
  },
  pm_2: {
    icon: satsOnchain,
    value: "onchain",
  },
  pm_3: {
    icon: usdtEthereum,
    value: "ethereum",
  },
  pm_4: {
    icon: usdtLightning,
    value: "lightning",
  },
  // pm_5: {
  //   icon: usdtLightning,
  //   value: "tron",
  // },
};

export const getSelectedMethod = (methodId) => {
  return paymentMethodData?.[methodId]?.value;
};

export const defaultPaymentMethodForSelectedAsset = [
  {
    id: "pm_1",
    payment_method_name: "lightning",
    is_payment_method_enabled: true,
    is_available_for_all: true,
    chain_asset_identifier: "SATS",
    amount_paid_tolerance: 0.0,
    weight: 1,
  },
  {
    id: "pm_2",
    payment_method_name: "onchain",
    is_payment_method_enabled: true,
    is_available_for_all: true,
    chain_asset_identifier: "SATS",
    amount_paid_tolerance: 1.0,
    weight: 2,
  },
];

export const getSelectedCurrencyAmount = (amount, currency, multiplier) => {
  if (amount >= 0) {
    return showAmount({
      amount,
      currency,
      showCommaSeparated: true,
      appendCurrency: true,
      multiplier,
    });
  } else {
    return `0.0 ${currency}`;
  }
};

export const dummyPaymentObj = {
  SATS: {
    lightning: dummyLightningAddress,
    onchain: dummyOnchainAddress,
    exchange_rate: 1,
    target_amount: 1,
  },
  USDT: {
    lightning: dummyLightningUSDTAddress,
    ethereum: dummyEthereumAddress,
    exchange_rate: 1,
    target_amount: 0,
  },
};

const handleData = (isFromStaticData, paymentInfo) => {
  if (isFromStaticData) {
    return {
      ...dummyPaymentObj,
      SATS: {
        ...dummyPaymentObj?.SATS,
        target_amount: 141.6 * 1523.00036,
      },
      USDT: {
        ...dummyPaymentObj?.USDT,
        target_amount: 141.6,
      },
    };
  } else if (paymentInfo?.targetCurrency) {
    return {
      ...dummyPaymentObj,
      [paymentInfo?.targetCurrency]: {
        ...dummyPaymentObj[paymentInfo?.targetCurrency],
        exchange_rate: paymentInfo?.payment?.exchange_rate,
        target_amount:
          paymentInfo?.payment?.exchange_rate * paymentInfo?.amount,
      },
    };
  } else {
    return dummyPaymentObj;
  }
};

export const handleSetInitialPaymentsObj = (
  WebPreviewProps,
  paymentInfo,
  from,
  isFromStaticData
) => {
  const res = WebPreviewProps?.accountAsset?.map((asset) => {
    return {
      [asset?.uuid]: asset?.payment_method_details?.reduce(
        (a, v) => ({
          ...a,
          [v?.payment_method_name]: "",
          exchange_rate: 1,
          target_amount: 0,
        }),
        {}
      ),
    };
  });

  const initialPaymentDetailObj = res.reduce(
    (acc, obj) => ({ ...acc, ...obj }),
    {}
  );

  let updatePaymentDetailObj = {};

  if (from !== "checkout") {
    updatePaymentDetailObj = handleData(isFromStaticData, paymentInfo);
  } else {
    const {
      payment_method_options,
      exchange_rate,
      payment_method,
      target_currency,
    } = {
      ...paymentInfo?.payment,
    };

    if (payment_method_options && exchange_rate) {
      const paymentRequest =
        payment_method_options[
          payment_method === "onchain" ? "on_chain" : payment_method
        ]?.payment_request;

      const lowerAmount = showAmount({
        amount: paymentInfo?.amount,
        currency: paymentInfo?.currency?.code,
        divideAmount: true,
      });
      const targetAmount = lowerAmount * exchange_rate;

      // set initial object for payment address according to the currency and payment method coming from response
      updatePaymentDetailObj = {
        ...initialPaymentDetailObj,
        [target_currency]: {
          ...initialPaymentDetailObj[target_currency],
          [payment_method]: paymentRequest,
          exchange_rate,
          target_amount:
            target_currency === "SATS" ? Math.ceil(targetAmount) : targetAmount,
        },
      };
    }
  }

  return updatePaymentDetailObj;
};

export const removeNumberFormatting = (amount) => {
  return OSREC?.parse(amount);
};

export const nodeNameStatus = {
  active: {
    label: "Active",
    variant: "success",
    value: "active",
  },
  deactivate: {
    label: "Deactivate",
    variant: "error-primary",
    value: "deactivate",
  },
  disconnected: {
    label: "Disconnected",
    variant: "default",
    value: "disconnected",
  },
};

export const makeBoldString = (data, count) => {
  if (data?.length > count + 6) {
    const address = data?.slice(0, count) + "..." + data?.slice(-count);
    const boldStart = address?.substring(0, 4);
    const boldEnd = address?.substring(address.length - 4);
    const middle = address?.substring(4, address.length - 4);
    return (
      <>
        <span style={{ color: "#0A193E", fontWeight: "600" }}>{boldStart}</span>
        <span style={{ color: "#848B9E" }}>{middle}</span>
        <span style={{ color: "#0A193E", fontWeight: "600" }}>{boldEnd}</span>
      </>
    );
  } else {
    return <span style={{ color: "#0A193E", fontWeight: "600" }}>{data}</span>;
  }
};

export const displayCurrencyWiseAmount = (amount, targetCurrency) => {
  const showAmountTargetCurrency =
    targetCurrency === "SATS" ? "BTC" : targetCurrency;
  return `${getCurrencyObj(showAmountTargetCurrency)?.symbol} ${showAmount({
    amount: amount,
    currency: targetCurrency,
    targetedCurrency: showAmountTargetCurrency,
    showCommaSeparated: true,
  })}`;
};

export const validateAmountRange = ({
  startAmount,
  endAmount,
  setErrorMessage,
  optionalEndAmount = false,
}) => {
  if (endAmount && !startAmount) {
    setErrorMessage?.(validStartAmountMsg);
    return false;
  } else if (!optionalEndAmount && startAmount && !endAmount) {
    setErrorMessage?.(validEndAmountMsg);
    return false;
  } else if (startAmount && endAmount) {
    const isGreaterEndAmount = +startAmount < +endAmount;
    !isGreaterEndAmount &&
      setErrorMessage?.(validEndAmountGreaterStartAmountMsg);
    return isGreaterEndAmount;
  } else return true;
};
